

























import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'PromoCampaignFormCompany',
  mixins: [PromoCampaignFormMixin],

  components: {
    Tooltip
  },

  computed: {
    rules(): ValidationRules {
      return {
        preSaleCoefficientInput: [
          ...this.positiveNumberRules,
          (v) => vr.lte(v, 5000),
          (v) => vr.gte(v, 0.01)
        ]
      };
    }
  }
};
